<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <add-advertiser-modal></add-advertiser-modal>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table :items="advertiserData" :fields="fields" responsive="sm" :busy="isBusy" :per-page="perPage"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" tbody-tr-class="rowClass"
                :filter-included-fields="filterOn" @row-clicked="advertiserSelected">
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(phone_number)="row">
                  +{{ row.item.country_code }}-{{ row.item.phone_number }}
                </template>
                <template #cell(email)="row">
                  {{ row.item.email }} <br />
                </template>
                <template #cell(company)="row">
                  <ul>
                    <li v-for="company, index in row.item.companies" v-bind:key="index">
                      <edit-company-modal v-on:edit="getAllUser()" :data="company" />
                      <div>
                        Name: {{ company.name }}
                      </div>
                      <div>
                        Trade License: {{ company.trade_license_number }}
                      </div>
                    </li>
                  </ul>
                  <br />
                  <add-company-modal v-on:change="getAllUser()" :data="row.item._id" />
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                      @change="updatePage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { advertiser } from "@/config/api/advertiser";
import EditCompanyModal from "@/views/pages/advertiser/edit-company-modal.vue";
import AddCompanyModal from "@/views/pages/advertiser/add-company-modal.vue";
import AddAdvertiserModal from "@/views/pages/advertiser/add-advertiser-modal.vue";

export default {
  components: { Layout, EditCompanyModal, AddCompanyModal, AddAdvertiserModal },
  data() {
    return {

      advertiserData: [],
      isBusy: false,
      totalRows: 1,
      selectedDriver: null,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: null,
      sortDesc: null,
      fields: [
        { key: "email", sortable: false },
        { key: "phone_number", sortable: false },
        { key: "Company", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() { },
  created() {
    this.getAllUser();
  },
  watch: {
    filter: function (newVal) {
      if (newVal.length > 2 || newVal.length === 0) {
        setTimeout(() => {
          this.currentPage = 1;
          this.getAllUser(this.filter);
        }, 2000);
      }
    },
  },
  methods: {
    updatePage(page) {
      this.currentPage = page;
      this.getAllUser();
    },
    getAllUser(search = "") {
      this.isBusy = true;
      const api = advertiser.get;
      api.params = { page: this.currentPage, search: search };
      this.generateAPI(api)
        .then((res) => {
          this.advertiserData = res.data.advertisers[0].totalData;
          this.totalRows = res.data.advertisers[0].totalCount[0].count;
        })
        .catch((err) => {
          console.error(err.response.data);
        }).finally(() => { this.isBusy = false; });
    },
    advertiserSelected(data) {
      this.$router.push({
        name: "AdvertiserDetails",
        params: { id: data._id },
      });
    },
  },
};
</script>

<style>
.rowClass {
  cursor: pointer;
}

.rowClass:hover {
  background: #f2f2f2;
  /* color: #ffffff; */
}
</style>